<template>
  <!-- 副标题 -->
  <div
    class="subtitle"
    :class="type"
  >
    <div
      class="zh"
      :style="zhStyle"
    >
      {{ zh }}
    </div>
    <div class="en">
      {{ cEn }}
    </div>
    <div
      v-if="$slots.tips"
      class="tips"
      :style="tipsStyle"
    >
      <slot name="tips" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    zh: {
      type: String,
      default: ''
    },
    en: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    zhStyle: {
      type: String,
      default: ''
    },
    tipsStyle: {
      type: String,
      default: ''
    }
  },
  computed: {
    // 转换大写
    cEn: function () {
      if (this.type === 'mini') {
        return this.en
      }
      return this.en.trim().toUpperCase()
    }
  },
  mounted () {}
}
</script>

<style lang="less" scoped>
.subtitle {
  position: relative;
  margin-bottom: 30px;
  .en {
    font-size: 102px;
    font-family: DIN-Bold, DIN;
    font-weight: bold;
    color: #f1f1f1;
    text-align: center;
    line-height: 1;
    white-space: nowrap;
  }
  .zh {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 32px;
    color: #000;
    z-index: 1;
  }
  .tips {
    font-size: 14px;
    color: #6d7281;
    text-align: center;
    margin-bottom: 70px;
  }

  // 选择样式
  &.mini {
    .zh {
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      text-align: center;
      margin-bottom: 10px;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .line{
        width: 39px;
        border: 1px solid #acacac;
      }
      &::before{
        content: "";
        .line();
        margin-right: 10px;
      }
      &::after {
        content: "";
        .line();
        margin-left: 10px;
      }
    }
    .en {
      font-size: 18px;
      color: #8f8f8f;
      margin-bottom: 21px;
      font-weight: 400;
    }
  }
}
</style>
