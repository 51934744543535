<template>
  <div class="product-main">
    <div class="product-main-l">
      <img :src="data.imgUrl">
    </div>
    <div class="product-main-r">
      <div class="title">
        {{ data.title }}
      </div>
      <div class="content">
        {{ content230 }}
      </div>
      <div class="btn">
        <router-link
          class="news-list-detail-link"
          :to="{
            path: '/newsCenter/newsDetail',
            query: {
              id: data.siteNewsId
            }
          }"
          v-if="data.type === 0"
        >
          <z-button
            type="hollow"
            @click.native="toDetail(data.id)"
          >
            了解产品详情
          </z-button>
        </router-link>
        <a
          :href="data.jumpUrl"
          target="_blank"
          v-else
        >
          <z-button
            type="hollow"
            @click.native="toDetail(data.id)"
          >
            了解产品详情
          </z-button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import zButton from '@/components/button/index.vue'
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        id: '',
        imgUrl: '',
        title: '',
        content: ''
      })
    }
  },
  components: {
    zButton
  },
  computed: {
    content230 () {
      if (this.data.content.length > 230) {
        return this.data.content.substring(0, 230) + '...'
      } else {
        return this.data.content
      }
    }
  },
  methods: {
    toDetail (id) {
      console.log('id', id)
    }
  }
}
</script>

<style lang="less" scoped>
.product-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 66px;
  padding: 30px 20px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
  box-shadow: 0px 3px 6px 1px rgba(73, 120, 250, 0.17);
  border-radius: 4px;
  border: 3px solid #ffffff;
  transition: box-shadow 0.1s;
  &:hover {
    box-shadow: 0px 0px 0px 0px rgba(73, 120, 250, 0.17);
  }
  .product-main-l {
    width: 495px;
    height: 310px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 150px;
      width: 220px;
      background: #004981;
    }
    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      height: 70px;
      width: 100px;
      background: #1890FF;
    }
    img {
      position: relative;
      z-index: 1;
      width: 95%;
      height: 94%;
      background: @imgBgColor;
    }
  }
  .product-main-r {
    width: 49%;
    .title {
      font-size: 20px;
      color: #000000;
      font-weight: bold;
      line-height: 27px;
      padding-left: 10px;
      border-left: 4px solid #3060e4;
      margin-bottom: 40px;
    }
    .content {
      height: 130px;
      font-size: 14px;
      color: #6d7281;
    }
  }
}
</style>
