<template>
  <div
    class="nav-bar"
    :class="{ 'navbar-fixed': fixed }"
    ref="navbar"
  >
    <template v-for="(item, index) in data">
      <div
        class="nav-bar-list"
        :key="index"
        @click="getRefScroll(item.ref)"
      >
        <div class="nav-bar-list-item">
          {{ item.name }}
        </div>
      </div>
      <div
        class="nav-bar-line"
        :key="'line-' + index"
      />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [
        {
          name: '方案详情',
          ref: 'overview'
        },
        {
          name: '功能框架',
          ref: 'framework'
        },
        {
          name: '方案优势',
          ref: 'advantages'
        },
        {
          name: '客户案例',
          ref: 'stories'
        }
      ]
    }
  },
  data () {
    return {
      scrollY: 0,
      startScrollY: 0,
      fixed: false
    }
  },
  methods: {
    /**
     * 获取界面滚动位置
     */
    getScrollY () {
      this.scrollY =
        document.documentElement.scrollTop || document.body.scrollTop
      // 设置行内样
      if (this.scrollY > this.startScrollY) {
        this.fixed = true
      } else {
        this.fixed = false
      }
    },
    /**
     * 获取ref(id)滚动位置
     */
    getRefScroll (ref) {
      const scrollElem = document.getElementById(ref) // 对应id的命名
      window.scrollTo({
        top: scrollElem.offsetTop - 150, // y 上线
        left: 0, // x 左右
        behavior: 'smooth' // 属性值：默认值auto（相当于instant）、平滑滚动smooth、瞬间滚动instant
      })
    }
  },
  mounted () {
    window.addEventListener('scroll', this.getScrollY)
    this.startScrollY = this.$refs.navbar.offsetTop - 70
  },
  // 离开
  beforeDestroy () {
    window.removeEventListener('scroll', this.getScrollY)
  }
}
</script>

<style lang="less" scoped>
.nav-bar {
  position: relative;
  height: 66px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: @mxnWith100vw;
    min-width: @minWith;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.05);
  }
  &-list {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-item {
      font-size: 20px;
      font-weight: 400;
      color: #2d3460;
      cursor: pointer;
      &:not(:last-child) {
        border-right: 1px solid #7070703d;
      }
    }
  }
  &-line {
    height: 31px;
    width: 1px;
    background: #333;
    opacity: 0.1;
    &:last-child {
      display: none;
    }
  }
  &.navbar-fixed {
    position: fixed;
    top: 70px;
    z-index: 9;
    width: 1180px;
    // min-width: unset;
    animation: show 0.3s;
    // 从上往下显示动画
    @keyframes show {
      0% {
        opacity: 0;
        transform: translate(0, -100%);
      }
      100% {
        opacity: 1;
        transform: translate(0, 0);
      }
    }
  }
}
</style>
