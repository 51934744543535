<template>
  <div class="solution-overview-type">
    <div class="list">
      <div
        class="item"
        :class="{ active: activeIndex === index }"
        v-for="(item, index) in typeList"
        :key="index"
        @click="handleClick(item, index)"
      >
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex: {
      type: Number,
      default: 0
    },
    typeList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {}
  },
  methods: {
    handleClick (item, index) {
      this.$emit('update:activeIndex', index)
    }
  }
}
</script>

<style lang="less" scoped>
.solution-overview-type {
  margin-bottom: 30px;

  .list {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .item {
      font-size: 14px;
      color: #2d3460;
      padding: 6px 16px;
      background: #fff;
      border-radius: 2px;
      box-shadow: 0px 0px 4px 0px rgba(107, 107, 107, 0.23);
      cursor: pointer;
      transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
      margin-bottom: 28px;
      margin-right: 34px;

      &:hover {
        box-shadow: 0px 0px 0px 0px rgba(107, 107, 107, 0.23);
        color: #4978fa;
      }

      &.active {
        color: #4978fa;
      }
    }
  }
}
</style>
