<template>
  <div class="scheme-advantages">
    <v-subtitle
      zh="方案优势"
      en="Scheme advantages"
      type="mini"
    />
    <div class="scheme-advantages-types types-list-4">
      <v-type
        v-for="(item, index) in typeslist1"
        :key="index"
        :item="item"
      />
    </div>
    <div class="scheme-advantages-types types-list-3">
      <v-type
        v-for="(item, index) in typeslist2"
        :key="index"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vType from './type.vue'
export default {
  components: {
    vSubtitle,
    vType
  },
  // computed: {
  //   typesList4 () {
  //     return this.data.slice(0, 4)
  //   },
  //   typesList3 () {
  //     return this.data.slice(4, 7)
  //   }
  // },
  props: {
    typeslist1: {
      type: Array,
      default: () => []
    },
    typeslist2: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.scheme-advantages {
  margin-bottom: 80px;
  padding-bottom: 20px;
  &-types {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }
}
</style>
