<template>
  <div
    class="solution-overview"
    id="overview"
  >
    <v-subtitle
      zh="方案详情"
      en="Solution overview"
      type="mini"
    >
      <template slot="tips">
        <div style="text-align: center">
          <p style="width: 860px; display: inline-block">
            {{ data.subTitleData }}
          </p>
        </div>
      </template>
    </v-subtitle>
    <v-type
      :active-index.sync="activeIndex"
      :type-list="data.list"
    />
    <v-select-type
      :data="data.list[activeIndex]"
      v-if="data.list.length > 0"
    />
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vType from './type.vue'
import vSelectType from './selectType.vue'
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        subTitleData: {},
        list: []
      })
    }
  },
  components: {
    vSubtitle,
    vType,
    vSelectType
  },
  data () {
    return {
      activeIndex: 0
    }
  }
}
</script>

<style lang="less" scoped>
.solution-overview {
  margin-bottom: 80px;
}
</style>
