<template>
  <div class="scheme-advantages-item">
    <div class="types-img">
      <img :src="item.img">
    </div>
    <div class="types-title">
      {{ item.title }}
    </div>
    <div class="types-content">
      {{ item.content }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        img: '',
        title: '',
        content: ''
      })
    }
  }
}
</script>

<style lang="less" scoped>
.scheme-advantages-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:not(:last-child) {
    margin-right: 75px;
  }
  .types {
    &-img {
      width: 72px;
      height: 72px;
      margin-bottom: 14px;

      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 100%;
        width: 100%;
      }
    }
    &-title {
      margin-bottom: 18px;
      font-size: 20px;
    }
    &-content {
      font-size: 14px;
      line-height: 1.2;
      color: #6d7281;
      max-width: 200px;
      text-align: center;
    }
  }
}
</style>
