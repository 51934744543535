<template>
  <div class="case-item">
    <div class="case-img">
      <img :src="item.imgUrl">
    </div>
    <div class="case-title">
      {{ item.title }}
    </div>
    <div class="case-content">
      {{ item.content }}
    </div>
    <div
      class="case-more"
      @click="toMore"
    >
      <router-link
        class="news-list-detail-link"
        :to="{
          path: '/newsCenter/newsDetail',
          query: {
            id: item.siteNewsId
          }
        }"
        v-if="item.type === 0"
      >
        <span>了解更多</span>
      </router-link>
      <a
        :href="item.jumpUrl"
        target="_blank"
        v-else
      >
        <span>了解更多</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        id: '',
        imgUrl: '',
        title: '',
        content: ''
      })
    }
  },
  methods: {
    toMore () {
      console.log('this.item', this.item)
    }
  }
}
</script>

<style lang="less" scoped>
.case {
  &-item {
    padding: 30px 40px;
    box-sizing: border-box;
    width: 387px;
    height: 442px;
    background: linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
    box-shadow: 0px 3px 6px 1px rgba(73, 120, 250, 0.17);
    border-radius: 4px;
    border: 3px solid #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      margin-right: 75px;
    }
    transition: box-shadow 0.3s;
    &:hover {
      box-shadow: 0px 0px 0px 0px rgba(73, 120, 250, 0.17);
    }
  }
  &-img {
    width: 300px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-title {
    font-size: 20px;
  }
  &-content {
    font-size: 14px;
    color: #6d7281;
  }
  &-more {
    width: 100%;
    text-align: right;
    span {
      display: inline-block;
      font-size: 14px;
      color: #3060e4;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
