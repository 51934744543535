<template>
  <div
    class="functional-framework"
    id="framework"
  >
    <v-subtitle
      zh="功能框架"
      en="Functional framework"
      type="mini"
    />
    <div class="functional-framework-content">
      <img :src="imgurl">
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
export default {
  components: {
    vSubtitle
  },
  props: {
    imgurl: {
      type: String,
      default: 'static/img/theSolution/functional-framework.png'
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.functional-framework {
  margin-bottom: 80px;

  &-content {
    img {
      width: 100%;
    }
  }
}
</style>
